
import http from "@/http";
import formHttp from "@/http/formHttp";
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const showAlert = ref<boolean>(false);
    const isReady = ref<boolean>(false);

    /** Ajout de la référence par le param passé en URL */
    const setReferenceHeader = (reference: string) => {
      http.interceptors.request.use((request) => {
        request.headers["X-REFERENCE"] = reference;
        return request;
      });
    };

    onBeforeMount(() => {
      const reference = route.params.reference as string;
      setReferenceHeader(reference);
    });

    onMounted(async () => {
      const reference = route.params.reference as string;
      try {
        await formHttp.getReference(reference);
        isReady.value = true;
      } catch (error) {
        showAlert.value = true;
      }
    });

    return { showAlert, isReady };
  },
});
